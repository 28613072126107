import React, { Suspense, useEffect } from 'react';

import { HashRouter, Route, Routes } from "react-router-dom";

import Header from "./components/header";
import Footer from "./components/footer";

import Home from "./pages/home"

const App = () => {
    useEffect(()=>{
        window.Webflow && window.Webflow.destroy();
        window.Webflow && window.Webflow.ready();
        window.Webflow && window.Webflow.require('ix2');
        document.dispatchEvent(new Event('readystatechange'))
    })

    return (
        <React.StrictMode>
            <Suspense
                fallback={
                    <div className="loadingPage">
                    </div>
                }
            >

                <Header />
                    <section id="what-are-sloties" className="section mint-section">
                        <div className="container minting-container">
                        {
                            <HashRouter>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={<Home />}
                                    />
                                </Routes>

                               
                            </HashRouter>
                        }
                        </div>
                    </section>
                <Footer />

            </Suspense>
        </React.StrictMode>
    )
}

export default App