import React from 'react';
import { useCountdown } from '../../hooks/useCountdown';
import './index.css'

const Countdown = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <h4>Expired!</h4>
  } else {
    return (
      <div className='show-counter'>
        <div>
            <span className="numbers">{days}</span>
            <span className="values">Days</span>
        </div>
        <div className='dots'>:</div>
        <div>
            <span className="numbers">{hours}</span>
            <span className="values">Hrs</span>
        </div>
        <div className='dots'>:</div>
        <div>
            <span className="numbers">{minutes}</span>
            <span className="values">Mins</span>
        </div>
        <div className='dots'>:</div>
        <div>
            <span className="numbers">{seconds}</span>
            <span className="values">Secs</span>
        </div>
      </div>
    );
  }
};

export default Countdown
