/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "./index.css"

import { ethers } from "ethers"

import Config from "config";

import Connect from "components/connect";
import StayUpdated from "components/stayUpdated";

import useWeb3 from "hooks/useWeb3";

import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { initializeApp } from 'firebase/app';

import { Oval } from  'react-loader-spinner'

import Card from 'components/card';
import Info from "components/info";
import Countdown from 'components/countDown';

import Logo from "assets/images/favicon.png"

let app;
if (process.env.NODE_ENV === "development") {
    app = initializeApp({
        /*apiKey: "AIzaSyDrDPeO5NrSnvzc85fDdEY_wx7ZKaiyLLU",
        authDomain: "slotie-baa44.firebaseapp.com",
        projectId: "slotie-baa44",
        storageBucket: "slotie-baa44.appspot.com",
        messagingSenderId: "620052493222",
        appId: "1:620052493222:web:96c2f343d1d8e0f2381567"*/
        projectId:"slotie-test",
        storageBucket:"slotie-test.appspot.com",
        authDomain:"slotie-test.firebaseapp.com",
        apiKey:"AIzaSyCcSSPWl_v2nWG34ohhOFkFXLdUL1rjCyI"
    })
} else {
    app = initializeApp({
        apiKey: "AIzaSyDrDPeO5NrSnvzc85fDdEY_wx7ZKaiyLLU",
        authDomain: "slotie-baa44.firebaseapp.com",
        projectId: "slotie-baa44",
        storageBucket: "slotie-baa44.appspot.com",
        messagingSenderId: "620052493222",
        appId: "1:620052493222:web:96c2f343d1d8e0f2381567"
        //projectId:"slotie-test",
        //storageBucket:"slotie-test.appspot.com",
        //authDomain:"slotie-test.firebaseapp.com",
        //apiKey:"AIzaSyCcSSPWl_v2nWG34ohhOFkFXLdUL1rjCyI"
    })
}

const firestore = getFirestore(app);
const functions = getFunctions(app);

const ASSET_TYPES = {
    SLOTIE: "SLOTIE",
    JUNIOR: "JUNIOR",
    BOTH: "BOTH"
}

const slotieJuniorIndexOffset = 10001;
const minimumRequiredAssets = 3;
const baseScore = 1;
const baseScoreMultiplier = 10;

function getArgsFromEvent(contract, receipt, eventName, targetArg) {
    const requestIds = [];
    const logs = receipt.logs;
    const contractInterface = contract.interface
    
    for (const log_key in logs)
    {
        try {
            const parsed = contractInterface.parseLog(logs[log_key]);
            const name = parsed.name;

            if (name === eventName) {
                requestIds.push(parsed.args[targetArg]);
            }
        } catch (e) {
            continue;
        }
        
    }

    return requestIds;
}

const Home = () => {
    const [
        provider,
        signer,
        address,
        contractWatts,
        contractTransferExtenderV2,
        contractJunior,
        contractDao,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ] = useWeb3();

    const [isTx, setIsTx] = useState();
    const [txHash, setTxHash] = useState();
    const [txError, setTxError] = useState();

    const [chosenOption, setChosenOption] = useState();

    const [isVoting, setIsVoting] = useState();
    const [voteError, setVoteError] = useState();

    const [proposals, setProposals] = useState([]);
    const [activeProposal, setActiveProposal] = useState();

    const [sloties, setSloties] = useState([]);
    const [juniors, setJuniors] = useState([]);
    const [wattsBalance, setWattsBalance] = useState();
    const [eligibleAssetCount, setEligibleAssetCount] = useState();

    const [eligibleSlotieCount, setEligibleSlotieCount] = useState();
    const [eligibleJuniorCount, setEligibleJuniorCount] = useState();

    const [canUserVote, setCanUserVote] = useState(false);
    const [hasUserVoted, setHasUserVoted] = useState(false);
    const [isUserEligible, setIsUserEligible] = useState(false)
    const [userVotingPower, setUserVotingPower] = useState();

    useEffect(() => {
        getProposals();
    }, [address]);

    useEffect(() => {
        getUserAssets()
    }, [address, contractTransferExtenderV2, contractJunior, contractWatts]);

    useEffect(() => {
        getUserBurnedWatts();
    }, [address, contractDao, activeProposal]);

    useEffect(() => {
        getUserVotingPower();
        getUserEligibility();
    }, [address, activeProposal, wattsBalance])

    useEffect(() => {
        getUserVotingStatus();
    }, [address, activeProposal])

    async function setCorrectErrorMessage(e) {
        setTxError("An unexpected error occured and your transaction did not go through.");
        for (let error of Config.ERRORS.PHASE_1) {
            if (e.message.includes(error.error)) {
                let _msg = error.message;               
                setTxError(_msg)
                return;
            }
        }
    }

    async function getProposals() {
        if (address) {
            const querySnapshot = await getDocs(collection(firestore, "slotie_dao_proposals"));
            let _proposals = querySnapshot.docs.map((doc) => {
                return doc.data()
            })
            .sort((a,b) => parseInt(a.proposalID) - parseInt(b.proposalID))            

            let activeProposals = _proposals.filter(p => p.deadline > Math.floor(Date.now() / 1000));
            let _activeProposal = activeProposals.length > 0 ? activeProposals[activeProposals.length - 1] : undefined;          
            if (_activeProposal)
                setActiveProposal(_activeProposal);

            let previousProposals = _proposals.filter(p => p.deadline <= Math.floor(Date.now() / 1000));
            previousProposals.sort((a,b) => b.deadline - a.deadline)
            setProposals(previousProposals)
        }
    }

    async function getWalletOfOwner(owner, retryCount = 0) {
        const maxRetries = 10;
    
        try {
            let slotiesOfUserRaw = await contractTransferExtenderV2.slotieWalletOfOwner(address);
            return slotiesOfUserRaw;
        } catch (e) {
            console.log(e);
            
            const errorMessage = e.message || e;
            if (errorMessage.includes("timeout") && retryCount < maxRetries) {
                console.log("retrying getWalletOfOwner")
                return await getWalletOfOwner(owner, retryCount + 1);
            }
        }
    }
    
    async function getUserAssets() {
        if (address && contractTransferExtenderV2 && contractJunior && contractWatts) {
            try {
                let slotiesOfUserRaw = await getWalletOfOwner(address)
                let slotiesOfUser = slotiesOfUserRaw.filter(t => !t.eq(0)).map(t => t.toNumber());

                let juniorBalanceOfUserRaw = await contractJunior.balanceOf(address);
                let juniorBalanceOfUser = juniorBalanceOfUserRaw.toNumber();        
                let juniorsOfUser = [];
                for (let i = 0; i < juniorBalanceOfUser; i++) {
                    let tokenOfUser = await contractJunior.tokenOfOwnerByIndex(address, i);
                    juniorsOfUser.push(tokenOfUser.toNumber());
                }

                let wattsBalanceOfUser = await contractWatts.balanceOf(address);

                setSloties(slotiesOfUser);
                setJuniors(juniorsOfUser);
                setWattsBalance(wattsBalanceOfUser.toString())
            } catch (e) {
                console.log(e)
            }
        }        
    }

    async function getUserBurnedWatts() {
        if (address && contractDao && activeProposal) {            
            let burnedWatts = await contractDao.usersBurnedWatts(address, activeProposal.proposalID);
            
            if (burnedWatts.gte(ethers.BigNumber.from(activeProposal.burnFee)))
                setCanUserVote(true);
        }   
    }

    function getUserVotingPower() {
        if (address && activeProposal && wattsBalance) {
            switch(activeProposal.requiredAsset) {
                case ASSET_TYPES.SLOTIE: {
                    let eligible = sloties.filter(s => !activeProposal.usedAssets[s]);
                    let totalAssetCount = eligible.length;
                    let bonusAssets = totalAssetCount - minimumRequiredAssets;
                    let score = baseScore + (baseScore * baseScoreMultiplier * bonusAssets / 100)
                    if (score < baseScore)
                        score = 0
                    setUserVotingPower(score)
                    break;
                }
                case ASSET_TYPES.JUNIOR: {
                    let eligible = juniors.filter(s => !activeProposal.usedAssets[s + slotieJuniorIndexOffset]);                    
                    let totalAssetCount = eligible.length;
                    let bonusAssets = totalAssetCount - minimumRequiredAssets;
                    let score = baseScore + (baseScore * baseScoreMultiplier * bonusAssets / 100);
                    if (score < baseScore)
                        score = 0
                    setUserVotingPower(score)
                    break;
                }
                case ASSET_TYPES.BOTH: {
                    let eligible = sloties.filter(s => !activeProposal.usedAssets[s])
                        .concat(juniors.filter(s => !activeProposal.usedAssets[s + slotieJuniorIndexOffset]));
                    let totalAssetCount = eligible.length;
                    let bonusAssets = totalAssetCount - minimumRequiredAssets;
                    let score = baseScore + (baseScore * baseScoreMultiplier * bonusAssets / 100)
                    if (score < baseScore)
                        score = 0
                    setUserVotingPower(score)
                    break;
                }
                default:
                    throw new Error("invalid asset type")                    
            }
        }
    }

    function getUserVotingStatus() {
        if (address && activeProposal) {
            let userVote = activeProposal.proposalContent.votes[address.toLowerCase()]
            if (userVote) {
                setHasUserVoted(true);
                setChosenOption(activeProposal.proposalContent.options[userVote.option])
            }
        }
    }

    function getUserEligibility() {
        if (address && activeProposal && wattsBalance) {
            switch(activeProposal.requiredAsset) {
                case ASSET_TYPES.SLOTIE: {
                    let eligible = sloties.map(s => !activeProposal.usedAssets[s]);
                    let totalAssetCount = eligible.length;
                    setEligibleAssetCount(totalAssetCount)
                    setIsUserEligible(totalAssetCount >= minimumRequiredAssets)
                    return;
                }
                case ASSET_TYPES.JUNIOR: {
                    let eligible = juniors.map(s => !activeProposal.usedAssets[s + slotieJuniorIndexOffset]);
                    let totalAssetCount = eligible.length;
                    setEligibleAssetCount(totalAssetCount)
                    setIsUserEligible(totalAssetCount >= minimumRequiredAssets);                    
                    return;
                }
                case ASSET_TYPES.BOTH: {
                    let eligibleSloties = sloties.map(s => !activeProposal.usedAssets[s])
                    let eligibleJuniors = juniors.map(s => !activeProposal.usedAssets[s + slotieJuniorIndexOffset]);
                    let eligible = eligibleSloties.concat(eligibleJuniors);
                    let totalAssetCount = eligible.length;
                    setEligibleAssetCount(totalAssetCount);
                    setEligibleSlotieCount(eligibleSloties.length)
                    setEligibleJuniorCount(eligibleJuniors.length)
                    setIsUserEligible(eligibleSloties.length >= minimumRequiredAssets || eligibleJuniors.length >= minimumRequiredAssets);
                    return;
                }
                default:
                    throw new Error("Invalid asset type");
            }
        }
    }

    function getUserNextAction() {
        if (address && activeProposal && wattsBalance) {
            if (!isUserEligible) {
                return `Get at least ${minimumRequiredAssets} ${activeProposal.requiredAsset === ASSET_TYPES.BOTH ? "Sloties or Juniors" : activeProposal.requiredAsset}`;
            }
    
            if (!canUserVote) {
                return "Burn WATTs";
            }
    
            if (hasUserVoted) {
                return "There are no more actions for you."
            }
    
            return "Choose an option and vote."
        }
    }

    function getVoteType(requiredAsset) {
        switch(requiredAsset) {
            case ASSET_TYPES.SLOTIE:
                return "Sloties";
            case ASSET_TYPES.JUNIOR:
                return "Juniors";
            case ASSET_TYPES.BOTH:
                return "Sloties and Juniors";
            default:
                throw new Error("Invalid asset type");
        }
    }

    function getOptionLines(options) {
        if (!options)
            return []
        return options.map((o, i) => ({
            title: o,
            //sub_title: `+ ${userVotingPower} voting score`,
            //percent: 100,
            checked: chosenOption === i
        }))
    }

    function getPreviousOptionsLines(proposal) {
        if (!proposal)
            return [];
        let { options, votes } = proposal.proposalContent;
        if (!options)
            return [];
        let totalScore = Object.keys(votes).map(k => votes[k]).reduce((acc, v) => {
            return acc + v.score
        }, 0) || 1
        return options.map((o, i) => ({
            title: o,
            sub_title: `${getVotingStatsOfOption(o, options, votes).toFixed(2)} total voting score`,
            percent: (getVotingStatsOfOption(o, options, votes) / totalScore * 100).toFixed(2),
            checked: getUserVotedAnswer(proposal) === o
        }))
    }

    function getVotingStatsOfOption(option, options, votes) {
        if (option !== undefined && options !== undefined && votes !== undefined) {
            let optionIndex = options.indexOf(option)
            let _votes = Object.keys(votes).map(k => votes[k])
            let votesForThisOption = _votes.filter(v => v.option === optionIndex);
            let totalScore = votesForThisOption.reduce((acc, v) => {
                return acc + v.score;
            }, 0)
            return totalScore;
        }
        return 0;
    }

    function getUserVotedAnswer(proposal) {
        if (!address || !proposal)
            return "";
        let { votes } = proposal.proposalContent        
        let userVote = votes[address.toLowerCase()];
        if (userVote)
            return proposal.proposalContent.options[userVote.option];
        else
            return undefined
    }

    function getUserVotedScore(votes) {
        if (!address || !activeProposal)
            return "";
        let userVote = votes[address.toLowerCase()];
        if (userVote)
            return userVote.score;
        else
            return 0;
    }

    function pickOption(option) {
        if (!hasUserVoted && isUserEligible && canUserVote)
            setChosenOption(option)
    }

    async function burnWatts() {
        setTxError(undefined)

        try {            

            if (!activeProposal || Object.keys(activeProposal).length === 0) {
                setTxError(`Error: No active proposal.`)
                return;
            } 

            if (canUserVote) {
                setTxError(`Error: You can already vote.`)
                return;
            }

            if (ethers.BigNumber.from(wattsBalance).lt(ethers.BigNumber.from(activeProposal.burnFee))) {
                setTxError(`Error: You do not have enough WATTs to burn for this proposal.`)
                return;
            }

            setIsTx(true);
            const tx = await contractDao.connect(signer).DoProposalBurn(activeProposal.proposalID);

            setTxHash(tx.hash);
            await tx.wait();
            await getUserBurnedWatts();
        } catch (e) {
            console.log(e)
            await setCorrectErrorMessage(e);            
        }
        setTxHash(undefined);
        setIsTx(false);
    }

    async function vote() {
        setVoteError()
        try {
            const daoSubmitVote = httpsCallable(functions, 'daoSubmitVote');

            if (chosenOption === undefined || chosenOption === null) {
                setVoteError("Error: Please choose an option by clicking on it and then press vote again.");
                return;
            }

            if (hasUserVoted) {
                setVoteError(`Error: user already voted`);
                return;
            }

            if (!canUserVote) {
                setVoteError(`Error: user has not burned enough WATTs yet`)
                return;
            }

            if (!isUserEligible) {
                setVoteError(`Error: user is not eligible`);
                return;
            }         

            setIsVoting(true)

            let proposalId = activeProposal.proposalID;
            let option = chosenOption
            let msg = `Hereby I submit my vote for proposal #${parseInt(proposalId)} of Slotie DAO, choosing option ${parseInt(option) + 1}.`
            let sig = await provider.getSigner().signMessage(msg);     

            let result = await daoSubmitVote({
                proposalId,
                option,
                address,
                signature: sig
            })
            let { success } = result.data;

            if (!success)
                setVoteError(result.data.reason || "Error: Something went wrong with voting.")
            else {
                setHasUserVoted(true)
                setActiveProposal(s => ({
                    ...s,
                    proposalContent: {
                        ...s.proposalContent,
                        votes: {
                            ...s.proposalContent.votes,
                            [address.toLowerCase()]: {
                                option: chosenOption,
                                score: userVotingPower
                            }
                        }
                    }
                }))                
            }                
        } catch (e) {
            console.log(e)
            setVoteError("Error: Something went wrong with voting.")
        }
        setIsVoting(false)
    }

    const Loading = () => (
        <>
            <div id="w-node-d5084adc-18c2-8cd3-e678-d41da77696ae-b62521ab" className="text centred" style={{color: "#58d658"}}>Transaction is loading...</div>
            {
                txHash &&

                <>
                    <a id="w-node-_3eb213d0-fd57-540c-fe21-2cb07eac8d05-b62521ab" href={Config.URLS.ETHERSCAN + "/tx/" + txHash} className="link-block w-inline-block" target="_blank">
                        <div className="text bold centred">View transaction on Bscscan.</div>
                    </a>
                    <div data-w-id="531c44b4-0b49-e823-3a34-e7e8535fa824" data-animation-type="lottie" data-src="documents/lottieflow-loading-04-1-ffffff-easey.json" data-loop="1" data-direction="1" data-autoplay="1" data-is-ix2-target="0" data-renderer="svg" data-default-duration="3.1458333333333335" data-duration="0" id="w-node-_531c44b4-0b49-e823-3a34-e7e8535fa824-b62521ab" className="lottie buy-ticket-loading-lottie"></div>
                </>
            }

        </>
    )

    return (
        <>
        {
            isConnected && isCorrectChain ?
            <div className="wrapper redeem-ticket-wrapper">
                <div className="grid redeem-tickets-grid">
                    <div>
                        <div className="text h2">Welcome to the official Slotie DAO.</div>
                        <div className="horizontal-line"></div>
                        <div className="spacer _1em"></div>
                    </div>

                    <div style={{ textAlign: 'center' }} className="text">
                        <p>
                            Welcome to the Slotie DAO. Here you can vote on proposals made by the Slotie team and decide on the direction of the project!
                            To vote on Slotie proposals, you will need a minimum of {minimumRequiredAssets} Slotie OGs. To vote on Juniors proposals, you will need a minimum of {minimumRequiredAssets} Slotie Juniors.

                            <br/>
                            <br/>
                            How does it work? 
                            <br/>
                            In order to vote you need to complete the following steps:
                            <br/>
                            1. Have a minimum of {minimumRequiredAssets}  Sloties, Juniors or both (this changes per proposal)
                            <br/>
                            2. Burn WATTs in order to submit a vote. You can do this at the bottom of the screen.
                            <br/>
                            3. Choose an option and vote. You can do this at the bottom of the screen.

                            <br/>
                            <br/>
                            How much voting power do I have?
                            <br/>
                            If you have {minimumRequiredAssets} NFTs you get {baseScore} voting points. Each additional NFT gets you {baseScore * baseScoreMultiplier / 100} additional voting points.
                            
                            <br/>
                            <br/>
                            You are connected with wallet <i>{address}</i>.

                            <br/>                            
                            Let your voice be heard and vote now.
                        </p>
                    </div>                   

                    <div className="grid redeem-tickets-grid">
                        <div>                  
                            <div className="horizontal-line"></div>
                            <div className="spacer _1em"></div>
                            <div className="text h2">Vote Here</div>                            
                        </div>
                    </div>
                    
                    {
                        activeProposal && wattsBalance ?
                            <> 
                                <div style={{display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
                                    <div className="text h3" style={{marginBottom: 0}}>Voting will end in</div>
                                    <Countdown targetDate={activeProposal.deadline * 1000} /> 
                                </div>                                
                                {
                                    !hasUserVoted ?
                                        <Card 
                                            avatar={Logo} 
                                            name={getVoteType(activeProposal.requiredAsset) + " Vote"}
                                            badge="opened" 
                                            title={getVoteType(activeProposal.requiredAsset) + " Holders Only"}
                                            text={activeProposal.proposalContent.name}
                                            lines={getOptionLines(activeProposal.proposalContent.options)} 
                                            interactive={true}
                                            cb={pickOption}
                                            canUserVote={canUserVote}
                                        />
                                    :
                                        null
                                }
                                {
                                    isUserEligible ?
                                        <>
                                            {
                                                canUserVote ?
                                                    hasUserVoted ?
                                                        <Info 
                                                            type="success" 
                                                            value={getUserVotedScore(activeProposal.proposalContent.votes)} 
                                                            answer={getUserVotedAnswer(activeProposal)} 
                                                        />
                                                    :
                                                    <div className="form-block w-form" style={{marginTop: "5px"}}>
                                                        <div className="text max-tickets-text" style={{marginBottom: "15px"}}>
                                                            Your voting power: {userVotingPower}
                                                        </div>
                                                        <form id="wf-form-Buy-Ticket-Form" name="wf-form-Buy-Ticket-Form" data-name="Buy Ticket Form" className="form">
                                                            
                                                            {
                                                                !isVoting ?
                                                                    <input 
                                                                        value="Vote" 
                                                                        onClick={() => vote()} 
                                                                        className="button large mint-ticket-form-button w-button"                                                                              
                                                                    />
                                                                :
                                                                <>                                    
                                                                    <Oval
                                                                        height="40"
                                                                        width="40"
                                                                        color='#ffffff'
                                                                        ariaLabel='loading'                                        
                                                                    /> 
                                                                </>
                                                            }
                                                        </form>
                                                        {
                                                            voteError &&
                                                            <div className="error-message text centred" style={{color: "red"}}>
                                                                {voteError}
                                                            </div>
                                                        }
                                                    </div>
                                                :
                                                <>
                                                    {
                                                        !isTx ?
                                                            <span 
                                                                onClick={(e) => { e.preventDefault(); burnWatts(); }} 
                                                                id="w-node-_4013a355-e959-c384-dd5a-1d04264fa9bc-b62521ab" 
                                                                style={{marginBottom: "0px", marginTop: "20px"}} 
                                                                className="button large w-button"
                                                            >
                                                                Burn {ethers.utils.formatEther(ethers.BigNumber.from(activeProposal.burnFee))} WATTs to unlock voting
                                                            </span>
                                                        :
                                                            <></>
                                                    }

                                                    {
                                                        isTx && !txError ?
                                                            <Loading />
                                                        :
                                                            <></>
                                                    }

                                                    {
                                                        txError &&
                                                        <div className="error-message text centred" style={{color: "red"}}>
                                                            {txError}                                                     
                                                        </div>
                                                    }   
                                            </>
                                            }
                                        </>
                                    :   
                                        <>
                                            {
                                                eligibleAssetCount !== undefined ?
                                                    <p>
                                                        You are not eligible to vote. You need {minimumRequiredAssets} {getVoteType(activeProposal.requiredAsset).replace("and", "or")} that can be used to vote.
                                                    </p>
                                                :
                                                    null
                                            }
                                        </>                                        
                                }
                            </>                       
                        :
                            wattsBalance ?
                                <p>No active proposal found. Come back later to vote on a proposal.</p>
                            :
                                <p>Proposal is being loaded...</p>

                    }

                    <div className="grid redeem-tickets-grid">
                        <div>                            
                            <div className="horizontal-line"></div>
                            <div className="spacer _1em"></div>
                            <div className="text h2">Previous Proposals</div>
                        </div>
                    </div>
                    
                    {
                        proposals && wattsBalance ?
                        <div style={{ textAlign: 'center', overflow: `${proposals.length > 1 ? "scroll" : "visible"}`, height: `${proposals.length >= 1 ? "400px" : "0px"}`, overflowX: "hidden" }} className="text">
                           { 
                                proposals.map(proposal => {
                                    return <Card 
                                        avatar={Logo} 
                                        name={getVoteType(proposal.requiredAsset) + " Vote"}
                                        badge="closed" 
                                        title={getVoteType(proposal.requiredAsset) + " Holders Only"}
                                        text={proposal.proposalContent.name}
                                        lines={getPreviousOptionsLines(proposal)} 
                                        interactive={false}
                                        customCss={{marginBottom: "20px"}}   
                                        canUserVote={true}                  
                                    />
                                })
                            }
                        </div>
                        :
                            null
                    }
                    
                </div>
            </div>
             :
             isConnected && !isCorrectChain ?
                 <StayUpdated 
                     text={"You are not connected to the correct network. Please connect to the Binance Smart Chain network."}
                 />
             :
             <Connect connect={connect} />
        }
        </>
    );
};

export default Home;
