import { useState } from 'react';
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
import {ethers} from "ethers";
import Contracts from "../contracts/hardhat_contracts.json";

import Config from "config"

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: Config.NETWORK.INFURA_ID,
        }
    }
};

const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
    disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
});

const chainIdToName = {
    "1": "mainnet",
    "5": "goerli",
    "56": "bsc",
    "97": "bsc_testnet",
    "1337": "localhost"
}

export default function useWeb3() {
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [address, setAddress] = useState(null);
    const [contractWatts, setContractWatts] = useState(null);
    const [contractTransferExtenderV2, setContractTransferExtenderV2] = useState(null);
    const [contractJunior, setContractJunior] = useState(null);
    const [contractDao, setContractDao] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [isCorrectChain, setIsCorrectChain] = useState(undefined)
    const [triedAutoConnecting, setTriedAutoConnecting] = useState(null);

    async function initiateWeb3(proxy) {
        const _provider = new ethers.providers.Web3Provider(proxy);
        const _ethProvider = new ethers.providers.JsonRpcProvider(Config.NETWORK.ETH_RPC_URL);

        _provider.on("disconnect", (code, reason) => {
            console.log(code, reason);
            setIsConnected(false)
        });

        const _signer = await _provider.getSigner();
        const _address = await _signer.getAddress();        
        let { chainId } = await _provider.getNetwork();
        const _isCorrectChainId = chainId === Config.NETWORK.CHAIN_ID
        chainId = chainId.toString()       
        const ethChainId = Config.NETWORK.ETH_CHAIN_ID;

        let _contractWatts;
        let _contractTransferExtenderV2;
        let _contractJunior;
        let _contractDao;
        if (_isCorrectChainId){
            let WATTS = Contracts[chainId][chainIdToName[chainId]]["contracts"]["WATTSBsc"];
            _contractWatts = new ethers.Contract(WATTS.address, WATTS.abi, _provider);

            let Dao = Contracts[chainId][chainIdToName[chainId]]["contracts"]["SlotieDaoBsc"];
            _contractDao = new ethers.Contract(Dao.address, Dao.abi, _provider);

            let TransferExtender = Contracts[ethChainId][chainIdToName[ethChainId]]["contracts"]["WattsTransferExtensionV2"];
            _contractTransferExtenderV2 = new ethers.Contract(TransferExtender.address, TransferExtender.abi, _ethProvider);

            let Junior = Contracts[ethChainId][chainIdToName[ethChainId]]["contracts"]["SlotieJr"];
            _contractJunior = new ethers.Contract(Junior.address, Junior.abi, _ethProvider);
        }

        setProvider(_provider);
        setSigner(_signer);
        setAddress(_address);
        setContractWatts(_contractWatts);
        setContractTransferExtenderV2(_contractTransferExtenderV2);
        setContractJunior(_contractJunior);
        setContractDao(_contractDao);
        setIsConnected(true)
        setIsCorrectChain(_isCorrectChainId);
    }

    async function tryAutoConnect() {
        try {
            let { cachedProvider } = web3Modal;
            let cachedFromStorage = JSON.parse(localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER"));
    
            if(cachedProvider && cachedProvider !== "") {
                await initiateWeb3(await web3Modal.connectTo(cachedProvider))
                return true
            } else if (cachedFromStorage && cachedFromStorage != "") {
                await initiateWeb3(await web3Modal.connectTo(cachedFromStorage))
                return true
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    async function connect() {
        try {
            // await web3Modal.clearCachedProvider();
            let _proxy = await web3Modal.connect()
            await initiateWeb3(_proxy)
        } catch (e) {            
            if (e && e.message)
                alert(e.message)
        }
    }

    return [
        provider,
        signer,
        address,
        contractWatts,
        contractTransferExtenderV2,
        contractJunior,
        contractDao,
        isConnected,
        isCorrectChain,
        tryAutoConnect,
        triedAutoConnecting,
        setTriedAutoConnecting,
        connect
    ]
}