const Info = ({
  value,
  type = 'default',
  message = "Thank you for voting! \nYour %s voting score was added to the answer %t. \nResults will be displayed when voting will be over.",
  answer = "Yes"
}) => <h3 
    style={{color: type === 'success' ? '#7fcc8e' : type === 'error' ? '#ed4545' : '#fff', textAlign: 'center', fontSize: 18}}
    >{message.replace("%s", value).replace("%t", answer).split("\n").map(msg => {
        return <>
            {msg}
            <br/>
        </>
    })}</h3>;

export default Info;
