import React from 'react'
import './style.css'

const badges = {
    closed: {
        name: 'Closed',
        color: '#7c3bf1',
    },
    opened: {
        name: 'Opened',
        color: '#7c3bf1',
    }
}

const Card = ({ 
    avatar, 
    name, 
    badge, 
    title, 
    text, 
    lines,
    interactive,
    cb,
    customCss,
    canUserVote
}) => {
    return (
        <div className="custom-card" style={customCss}>
            <div>
                <div className="head-row">
                    <div>
                        <img src={avatar} alt={name} />
                        <span>{name}</span>
                    </div>
                    <div>
                        <span className="badge" style={{ backgroundColor: badges[badge].color }}>{badges[badge].name}</span>
                    </div>
                </div>
                {title ? <h2>{title}</h2> : null}
                {text ? <p dangerouslySetInnerHTML={{ __html: text }}></p> : null}
                {
                    lines && lines.length && canUserVote ? (
                        <ul>
                            {
                                lines.map((line, i) => (
                                    <li 
                                        className={`${line.checked ? 'check' : ''}`} 
                                        style={interactive ? { cursor: "pointer" } : null}
                                        onClick={() => cb(i)}
                                    >
                                        <div>
                                            {line.checked ? (
                                                <span className="check-icon" style={{backgroundImage: `url(/images/check.svg)`}} />
                                            ) : null}
                                            {line.title} <span>{line.sub_title}</span>
                                        </div>
                                        
                                        {
                                            line.percent ?
                                                <div className='percentage'>{line.percent}%
                                                </div>
                                            :
                                                null
                                        }                                        
                                    </li>
                                ))
                            }
                        </ul>
                    ) : null
                }
            </div>
        </div>
    )
}

export default Card
